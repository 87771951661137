<template>
  <div class="cart-submit">
    <app-loader v-if="loader" />

    <div class="cart-submit__agb">
      <label class="cart-submit__agb-checkbox">
        <input
          v-model="agbChecked"
          type="checkbox"
        >
        <span>
          Hiermit bestätige ich, dass ich die
          <a
            href="https://www.omni-media.de/Moplak_AGB.pdf"
            target="_blank"
          >AGB</a>
          und die
          <a
            href="https://www.moplak.de/unternehmen/datenschutzerklaerung/"
            target="_blank"
          >Datenschutzerklärung</a>
          sowie
          <a
            href="https://www.moplak.de/unternehmen/datenschutzerklaerung/kundeninformation/"
            target="_blank"
          >Kundeninformation zum Datenschutz</a>
          gelesen und verstanden habe und mich
          mit den Inhalten einverstanden erkläre.
        </span>
      </label>

      <div class="cart-submit__agb-error">
        {{ error }}
      </div>
    </div>

    <button
      class="btn btn--outfly"
      @click="submit"
    >
      Absenden
    </button>
  </div>
</template>

<script>
import eventbus from '@/utils/eventbus';

import AppLoader from '@/components/app-loader';

export default {
  components: {
    AppLoader,
  },
  data() {
    return {
      agbChecked: true,
      loader: false,
    };
  },
  computed: {
    error() {
      return this.agbChecked ? '' : 'Sie müssen mit den Bedingungen einverstanden';
    },
  },
  methods: {
    submit() {
      this.$store.dispatch('validateCartForm').then(() => {
        const { contactFormErrors, requestOptionsError } = this.$store.getters;
        const isValidContactForm = Object.values(contactFormErrors).filter((err) => err).length === 0;
        const isValidRequestOptions = !requestOptionsError;

        if (isValidContactForm && isValidRequestOptions) {
          this.loader = true;
          this.$store.dispatch('submitRequest')
            .then(() => {
              eventbus.$emit('open-message', {
                header: 'Erfolg',
                // eslint-disable-next-line max-len
                body: `Ihre Anfrage ist abgesendet.
        
        Eine PDF bekommen Sie per Email.`,
                buttons: {
                  'Weiter suchen': () => this.$router.push({ name: 'criteria' }),
                },
              });
            })
            .catch(() => {
              eventbus.$emit('open-message', {
                header: 'Fehler',
                // eslint-disable-next-line max-len
                body: `Es ist ein Fehler beim Absenden.
        
        Bitte versuchen Sie noch mal Später.`,
              });
            })
            .finally(() => {
              this.loader = false;
            });
        }
      });
    },
  },
};
</script>

<style lang="scss">
.cart-submit {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;

  @include media-bp(mob) {
    flex-direction: column;
    align-items: center;
  }

  &__agb {
    flex: 1 1 65%;
    max-width: 65%;

    @include media-bp(mob) {
      flex: 1 1 auto;
      max-width: none;
    }

    &-checkbox {
      display: flex;
      line-height: 1.6;
      font-size: 12px;
      cursor: pointer;

      input {
        @include form-checkbox;
      }

      a {
        color: $link-color;
        &:focus,
        &:hover {
          text-decoration-line: underline;
          color: $link-color-hover;
        }
      }
    }

    &-error {
      margin-top: 6px;
      height: 24px;
      color: $error-color;
      font-size: 12px;
    }
  }
}
</style>
