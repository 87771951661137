<template>
  <div class="cart-marker-row cart-table__row">
    <div class="cart-table__cell cart-table__cell--city cart-marker-row__cell cart-marker-row__cell--city">
      {{ city }}
    </div>
    <div class="cart-table__cell cart-table__cell--media cart-marker-row__cell cart-marker-row__cell--media">
      <app-media-image
        :type="marker.tp"
        image-type="image_banner"
      />
    </div>
    <div class="cart-table__cell cart-table__cell--info cart-marker-row__cell cart-marker-row__cell--info">
      <div class="cart-marker-row__cell--info-address">
        <app-media-image :type="marker.tp" />
        {{ address }}
      </div>
      <div class="cart-marker-row__cell--info-location-number">
        Standortnummer: <strong>{{ locationNumber }}</strong>
      </div>
      <img
        class="cart-marker-row__cell--info-photo"
        :src="photo"
        @click="openPhoto"
      >
    </div>
    <div
      class="
        cart-table__cell
        cart-table__cell--selection-total
        cart-marker-row__cell
        cart-marker-row__cell--selection-total
      "
    >
      <marker-selection
        :marker="marker"
        :info-as-popup="false"
        show-sum
        show-toggle-icon
        :include-marker-selection="true"
      />
    </div>
  </div>
</template>

<script>
import eventbus from '@/utils/eventbus';
import AppMediaImage from '@/components/app-media-image';
import MarkerSelection from '@/components/marker-selection';

export default {
  components: {
    AppMediaImage,
    MarkerSelection,
  },
  props: {
    marker: {
      type: Object,
      required: true,
    },
  },
  computed: {
    city() {
      return this.marker.ona;
    },
    address() {
      return `${this.marker.adr}, ${this.marker.plz}`;
    },
    locationNumber() {
      return `${this.marker.sto}\u2011${this.marker.postis}`;
    },
    photo() {
      return this.marker._serviceAttributes.image;
    },
  },
  methods: {
    openPhoto() {
      eventbus.$emit('open-image', this.photo);
    },
  },
};
</script>

<style lang="scss">
.cart-marker-row {
  &__cell {
    &--media {
      .app-media-image {
        width: 55px;
        height: 42px;
      }
    }

    &--info {
      &-address {
        display: flex;
        align-items: center;
        word-break: break-word;

        .app-media-image {
          height: 1.5em;
          margin-right: 8px;

          display: none;

          @include media-bp(tab) {
            display: block;
          }
        }
      }

      &-photo {
        width: 60px;
        height: 40px;
        cursor: pointer;
        margin-right: 5px;
      }
    }

    &--selection-total {
      .marker-selection {
        width: 100%;
      }
    }
  }

  .btn-delete {
    @include icon(trash);
    cursor: pointer;
  }

  .btn-add {
    @include icon(add);
    cursor: pointer;
  }
}
</style>
