<template>
  <div class="cart-form">
    <contact-form />

    <div class="cart-form__request">
      <div class="cart-form__request-header">
        <label class="cart-form__request-type">
          <input
            v-model="requestType"
            type="radio"
            name="requestType"
            value="offer"
          >
          <span>Angebot</span>
        </label>
        <label class="cart-form__request-type">
          <input
            v-model="requestType"
            type="radio"
            name="requestType"
            value="binding"
          >
          <span>Verbindliche Buchung</span>
        </label>
      </div>

      <div class="cart-form__request-content">
        <request-options-offer v-if="isOffer" />
        <request-options-binding v-if="isBinding" />

        <submit-form />
      </div>
    </div>
  </div>
</template>

<script>
import ContactForm from './contact-form';
import RequestOptionsOffer from './request-options-offer';
import RequestOptionsBinding from './request-options-binding';
import SubmitForm from './submit';

export default {
  components: {
    ContactForm,
    RequestOptionsOffer,
    RequestOptionsBinding,
    SubmitForm,
  },
  data() {
    return {
      activeTab: {
        left: true,
        right: false,
      },
      offersSelectedLeftTab: {
        first: false,
        second: false,
      },
      offersSelectedRightTab: {
        first: false,
        second: false,
        third: false,
      },
    };
  },
  computed: {
    requestType: {
      get() { return this.$store.getters.requestType; },
      set(value) { this.$store.dispatch('updateRequestType', value); },
    },
    requestOptions() {
      return this.$store.getters.requestOptions;
    },
    isOffer() {
      return this.requestType === 'offer';
    },
    isBinding() {
      return this.requestType === 'binding';
    },
  },
  mounted() {
    this.$store.dispatch('clearCartFormErrors');
  },
  methods: {
    toggleTab() {
      this.activeTab.left = !this.activeTab.left;
      this.activeTab.right = !this.activeTab.right;
    },
  },
};
</script>

<style lang="scss">
.cart-form {
  display: flex;
  flex-direction: column;
  align-items: stretch;

  &__request {
    &-header {
      display: flex;
      height: 60px;
    }

    &-type {
      flex: 1 1 50%;
      max-width: 50%;

      display: flex;
      align-items: stretch;

      span {
        flex: 1 1 100%;
        max-width: 100%;

        display: flex;
        justify-content: center;
        align-items: center;

        text-align: center;

        color: $cart-form-tabs-header-color;
        font-size: 24px;
        font-weight: 700;
        border-bottom: $cart-form-tabs-border;
        opacity: .8;
        cursor: pointer;
      }

      input {
        display: none;

        &:checked + span {
          border: $cart-form-tabs-border;
          border-bottom: none;
          cursor: default;
        }
      }
    }

    &-content {
      display: flex;
      flex-direction: column;
      padding: 16px;
      border: $cart-form-tabs-border;
      border-top: none;
    }
  }
}

.request-options {
  display: flex;
  flex-direction: column;
  position: relative;

  &__checkbox {
    margin-top: 10px;
    line-height: 1.6;

    display: flex;
    cursor: pointer;

    input {
      @include form-checkbox;
    }

    em {
      font-style: italic;
    }
  }

  &__error {
    margin-top: 6px;
    height: 24px;
    color: $error-color;
    font-size: 12px;
  }
}
</style>
