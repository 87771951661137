<template>
  <div class="request-options">
    <label class="request-options__checkbox">
      <input
        v-model="optionFirst"
        type="checkbox"
      >
      <span>
        Hiermit buche ich/wir die Stellenauswahl verbindlich und erhalte
        unverzüglich eine Auftragsbestätigung.
        <br>
        <em>(Bitte überprüfen Sie diese, da die Flächen vom Zwischenverkauf nicht ausgeschlossen sind.)</em>
      </span>
    </label>
    <label class="request-options__checkbox">
      <input
        v-model="optionSecond"
        type="checkbox"
      >
      <span>
        Hiermit buche ich/wir den Plakatdruck verbindlich <strong>mit</strong> Ersatzplakaten. Ich
        erhalte unverzüglich eine Auftragsbestätigung.
      </span>
    </label>
    <label class="request-options__checkbox">
      <input
        v-model="optionThird"
        type="checkbox"
      >
      <span>
        Hiermit buche ich/wir den Plakatdruck verbindlich <strong>ohne</strong> Ersatzplakate.
        Ich erhalte unverzüglich eine Auftragsbestätigung.
        <br>
        <em>
          (Bei einem Plakatdruck ohne Ersatz besteht bei eventuellen
          Reklamationen kein Ersatzanspruch auf Ausbesserung.)
        </em>
      </span>
    </label>

    <div class="request-options__error">
      {{ error }}
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    requestOptions: {
      get() { return this.$store.getters.requestOptions; },
      set(options) { this.$store.dispatch('updateRequestOptions', options); },
    },
    optionFirst: {
      get() { return this.requestOptions.places.binding; },
      set(value) {
        const requestOptions = { ...this.requestOptions };
        requestOptions.places.binding = value;

        this.requestOptions = requestOptions;
      },
    },
    optionSecond: {
      get() { return this.requestOptions.print.binding_with_replacement; },
      set(value) {
        const requestOptions = { ...this.requestOptions };

        requestOptions.print.binding_with_replacement = value;
        if (value) requestOptions.print.binding_without_replacement = !value;

        this.requestOptions = requestOptions;
      },
    },
    optionThird: {
      get() { return this.requestOptions.print.binding_without_replacement; },
      set(value) {
        const requestOptions = { ...this.requestOptions };

        requestOptions.print.binding_without_replacement = value;
        if (value) requestOptions.print.binding_with_replacement = !value;

        this.requestOptions = requestOptions;
      },
    },
    error() {
      return this.$store.getters.requestOptionsError;
    },
  },
};
</script>

<style lang="scss">
</style>
