<template>
  <div class="city-cart">
    <marker-cart
      v-for="marker in cityCartMarkers"
      :key="marker.id"
      :marker="marker"
    />
    <div class="city-cart__total cart-table__row">
      {{ city }}:
      <span class="city-cart__sum">
        {{ sum | euro }}
      </span>
    </div>
  </div>
</template>

<script>
import MarkerCart from './marker-cart';

export default {
  components: {
    MarkerCart,
  },
  props: {
    city: {
      type: String,
      required: true,
    },
  },
  computed: {
    cityCartMarkers() {
      return this.$store.getters.cartMarkersList
        .filter(({ ona }) => ona === this.city)
        .sort((a, b) => (a.adr > b.adr ? 1 : -1));
    },
    sum() {
      return this.cityCartMarkers.reduce((acc, marker) => acc + this.$store.getters.getMarkerCartSum(marker), 0);
    },
  },
};
</script>

<style lang="scss">
.city-cart {
  margin-bottom: 8px;
  &__total {
    display: flex;
    justify-content: flex-end;
    font-weight: bold;
    padding: 5px 10px;
  }

  &__sum {
    flex: 0 0 120px;
    max-width: 120px;
    text-align: right;
  }
}
</style>
