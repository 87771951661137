<template>
  <div class="request-options">
    Ich möchte ein unverbindliches Angebot über:
    <label class="request-options__checkbox">
      <input
        v-model="optionFirst"
        type="checkbox"
      >
      <span>meine Stellenauswahl</span>
    </label>
    <label class="request-options__checkbox">
      <input
        v-model="optionSecond"
        type="checkbox"
      >
      <span>den Plakatdruck</span>
    </label>

    <div class="request-options__error">
      {{ error }}
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    requestOptions: {
      get() { return this.$store.getters.requestOptions; },
      set(options) { this.$store.dispatch('updateRequestOptions', options); },
    },
    optionFirst: {
      get() { return this.requestOptions.places.offer; },
      set(value) {
        const requestOptions = { ...this.requestOptions };
        requestOptions.places.offer = value;
        this.requestOptions = requestOptions;
      },
    },
    optionSecond: {
      get() { return this.requestOptions.print.offer; },
      set(value) {
        const requestOptions = { ...this.requestOptions };
        requestOptions.print.offer = value;
        this.requestOptions = requestOptions;
      },
    },
    error() {
      return this.$store.getters.requestOptionsError;
    },
  },
};
</script>

<style lang="scss">
</style>
