<template>
  <div class="contact-form">
    <app-input
      v-for="({ value, label, required, type }, key) in contactForm"
      :key="key"
      class="contact-form__input"
      :class="`contact-form__input--${key}`"
      :placeholder="`${label || ''}${required ? '*' : ''}`"
      :value="value"
      :type="type"
      :error="getError(key)"
      @input="value => updateContactForm({ key, value })"
    />
  </div>
</template>

<script>
import AppInput from '@/components/app-input';

export default {
  components: {
    AppInput,
  },
  computed: {
    contactForm() {
      return this.$store.getters.contactForm;
    },
    contactFormErrors() {
      return this.$store.getters.contactFormErrors;
    },
    getError() {
      return (key) => this.contactFormErrors[key];
    },
  },
  methods: {
    updateContactForm({ key, value }) {
      this.$store.dispatch('updateContactForm', { key, value });
    },
  },
};
</script>

<style lang="scss">
.contact-form {
  $root: &;

  display: flex;
  flex-wrap: wrap;

  margin: 0 -15px;

  &__input {
    padding: 0 15px;

    &--person,
    &--firm,
    &--telefon,
    &--email {
      flex: 1 1 50%;
      max-width: 50%;
    }

    &--address {
      flex: 1 1 66.66%;
      max-width: 66.66%;
    }

    &--zipcity {
      flex: 1 1 33.33%;
      max-width: 33.33%;
    }

    &--message {
      flex: 1 1 100%;
      max-width: 100%;

      textarea {
        resize: none;
        outline: none;
        width: 100%;
        height: 144px;
      }
    }

    @include media-bp(tab) {
      flex: 1 1 100%;
      max-width: 100%;
    }
  }
}
</style>
