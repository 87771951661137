<template>
  <div class="cart-table">
    <app-loader v-if="loader" />

    <div class="cart-table__row cart-table__row--header">
      <div class="cart-table__cell cart-table__cell--city">
        Ort
      </div>
      <div class="cart-table__cell cart-table__cell--media">
        Medium
      </div>
      <div class="cart-table__cell cart-table__cell--info">
        Standort
      </div>
      <div class="cart-table__cell cart-table__cell--selection">
        Termin
      </div>
      <div class="cart-table__cell cart-table__cell--total">
        Gesamt
      </div>
    </div>

    <city-cart
      v-for="(city, index) in cartCities"
      :key="index"
      :city="city"
    />

    <div class="cart-table__total">
      <div class="cart-table__row">
        GESAMT Netto:
        <span>{{ cartTotalData.totalNetto | euro }}</span>
      </div>
      <div class="cart-table__row">
        MwSt {{ mwstProcents }}%:
        <span>{{ cartTotalData.totalMwst | euro }}</span>
      </div>
      <div class="cart-table__row">
        GESAMTPREIS:
        <span>{{ cartTotalData.total | euro }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import eventbus from '@/utils/eventbus';

import AppLoader from '@/components/app-loader';
import CityCart from './city-cart';

export default {
  components: {
    AppLoader,
    CityCart,
  },
  data() {
    return {
      loader: false,
    };
  },
  computed: {
    mwstProcents() {
      return this.$store.getters.mwstProcents;
    },
    cartCities() {
      return this.$store.getters.cartCities;
    },
    cartTotalData() {
      return this.$store.getters.cartTotalData;
    },
  },
  mounted() {
    this.loader = true;

    this.$store.dispatch('processCartData').then(() => {
      if (!this.$store.getters.isCartActual) {
        eventbus.$emit('open-message', {
          header: 'Achtung',
          // eslint-disable-next-line max-len
          body: `Ihre Auswahl ist nicht mehr tagesaktuell, die markierten Standorte sind leider zu den von Ihnen ausgewählten Termin/en nicht mehr verfügbar.
  
  Sie können die nicht aktuelle Termine entfernen und Alternativen auswählen
  
  Oder ohne Änderungen trotzdem fortfahren`,
        });
      }
    }).finally(() => { this.loader = false; });
  },
};
</script>

<style lang="scss">
.cart-table {
  $root: &;

  display: flex;
  flex-direction: column;
  width: 90%;
  margin: 25px auto 20px;

  @include media-bp(tab) {
    width: 100%;
  }

  &__row {
    display: flex;
    margin: 0 -10px;
    border-top: $cart-table-border;

    @include media-bp(mob) {
      flex-wrap: wrap;
      margin: 0;
    }

    &--header {
      border-top: none;
      border-bottom: $cart-table-border;
      border-bottom-width: 2px;

      text-align: start;
      font-weight: 700;
      font-size: 14px;
    }
  }

  &__cell {
    padding: 5px 10px;

    &--city {
      flex: 2 2 167.06px;

      @include media-bp(tab) {
        display: none;
      }
    }

    &--media {
      flex: 1 1 78.45px;

      @include media-bp(tab) {
        display: none;
      }
    }

    &--info {
      flex: 2 2 398.5px;
    }

    &--selection {
      flex: 1 1 195.75px;
    }

    &--total {
      flex: 1 0 102.85px;
    }

    &--selection-total {
      flex: 1 0 (195.75px + 102.85px + 20px);
      max-width: 100%;
    }
  }

  &__total {
    border-top: $cart-table-border;
    border-top-width: 2px;

    margin: 0 -10px;

    @include media-bp(mob) {
      margin: 0;
    }

    #{$root}__row {
      border: none;
      justify-content: flex-end;
      font-size: 1.05em;
      font-weight: bold;
      margin: 16px 10px 0 0;

      span {
        font-weight: normal;
        flex: 0 0 150px;
        text-align: right;
      }

      &:last-child {
        font-size: 1.2em;
        span {
          font-weight: bold;
        }
      }
    }
  }

}
</style>
