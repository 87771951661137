<template>
  <div class="cart">
    <section class="cart__section cart__section--selection">
      <div class="cart__text">
        Die folgende tabellarische Darstellung bietet Ihnen eine Übersicht der von
        Ihnen zur Reservierung vorgesehenen Werbeflächen:
      </div>
      <div class="cart__actions">
        <span
          class="action action--table"
          @click="getCsv"
        />
        <span
          class="action action--copy"
          @click="copyToClipboard"
        />
        <!-- <span class="action action--print" /> -->
      </div>

      <cart-table class="cart__table" />
    </section>

    <section class="cart__section cart__section--form">
      <cart-form />
    </section>
  </div>
</template>

<script>
import Vue from 'vue';
import VueClipboard from 'vue-clipboard2';

import eventbus from '@/utils/eventbus';

import CartForm from './components/form';
import CartTable from './components/table';

Vue.use(VueClipboard);

export default {
  components: {
    CartForm,
    CartTable,
  },
  methods: {
    getCsv() {
      this.$store.dispatch('getCsv');
    },
    copyToClipboard() {
      const { origin } = window;
      const { path } = this.$route;
      const { cartCode } = this.$store.getters;

      const link = `${origin}${path}/${cartCode}`;

      this.$copyText(link).then(() => {
        eventbus.$emit('open-message', {
          header: 'Link ist kopied',
          // eslint-disable-next-line max-len
          body: `Der Link von diesem Warenkorb ist in Clipboard kopiert

Sie können diesen im beliebigen Browser öffnen oder weiter leiten`,
        });
      });
    },
  },
};
</script>

<style lang="scss">
.cart {
  height: 100%;
  overflow-y: auto;

  &__section {
    padding: 25px 20px;

    @include media-bp(mob) {
      padding: 5px 5px;
    }

    &--selection {
      background-color: $section-gray-bg;
    }

    &--form {
      display: flex;
      justify-content: center;

      .cart-form {
        flex: 1 1 100%;
        max-width: 710px;
      }
    }
  }

  &__text {
    color: $font-color-primary;
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    width: 90%;
    margin: 10px auto 0;

    .action {
      width: 32px;
      height: 32px;
      color: $cart-action-color;
      font-size: 32px;
      cursor: pointer;

      margin-left: 25px;

      &--table {
        @include icon(table);
      }

      &--print {
        @include icon(printer);
      }

      &--copy {
        @include icon(copy);
      }
    }
  }

  &__form {
    padding: 25px 20px 20px;

    @include media-bp(mob) {
      padding: 5px 5px;
    }
  }
}
</style>
